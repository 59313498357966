@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: rgb(245,244,245);
}

@keyframes circular-progress {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

@keyframes circular-progress-inner {
  0% {
    stroke-dasharray: 1px,200px;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 100px,200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100px,200px;
    stroke-dashoffset: -125px;
  }
}

.error-message {
  color: #bf1650;
  font-size: 14px;
  margin: 4px 0 0 0;
}

.error-message::before {
  display: inline;
  content: "⚠ ";
}